// c1 remove page scroller
body {
  // height: 100vh;
  // overflow: hidden;
}
.assesment_container {
  // border: 2px solid red;
  padding: "0 rem";
  justify-content: space-between;
  overflow: hidden;

  .logo_container {
    background-color: #ffffff;
    color: #000000;
    // border: 2px solid green;
    display: flex;
    align-items: center;
    flex-direction: column;

    .main_section {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .left_section {
        // width: 30%;
        .assessment_logo {
          width: 120px;
        
          @media (max-width: 500px) {
            width: 100px;
          }
        }
        .logo_name {
          border-right: 1px solid #00000080;
          padding-right: 10px;
        }

        .title {
          color: #00000080;
          font-size: 18px;
          // border: 1px solid red;
        }
      }

      .mid_section {
        display: none;
      }

      @media (min-width: 768px) {
        .mid_section {
          // width: 40%;
          display: flex;
          align-items: center;
          justify-content: center;
    
          span {
          }
        }
        
      }

      .right_section {
        // width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .nav-item {
          // border: 1px solid red;
          .avatar {
            width: 30px;
            height: 30px;
            label {
              font-size: 12px;
            }
          }
          @media (min-width: 768px) {
            .avatar {
              width: 40px;
              height: 40px;
              label {
                font-size: 16px;
              }
            }
          }
        }

        h6 {
          margin: 0;
          font-size: 12px;
          @media (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  //
  .timer_section {
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    // border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left_section {
      width: 35%;
      span {
        display: flex;
        flex-direction: column;
        font-size: 12px;
      }
      @media (min-width: 500px) {
        span {
          display: flex;
          flex-direction: row;
          font-size: 14px;
        }
      }
    }

    .mid_section {
      width: 30%;
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        font-size: 10px;
        strong {
          text-align: center;
        }
      }
    }
    @media (min-width: 400px) {
      .mid_section {
        span {
          font-size: 12px;
        }
      }
    }
    @media (min-width:550px) {
      .mid_section {
        width: 30%;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          gap: 4px;
          font-size: 14px;
          strong {
            text-align: center;
          }
        }
      }
    }
    @media (min-width: 768px) {
      .mid_section {
        display: none;
      }
    }

    .right_section {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        font-size: 12px;
      }
    }
    @media (min-width: 500px) {
      .right_section {
        span {
          font-size: 14px;
        }
      }
    }
    @media (min-width: 768px) {
      justify-content: space-between;

      .left_section {
        width: 50%; /* Auto adjust width */
      }

      .right_section {
        width: 50%; /* Auto adjust width */
      }
    }
  }

  //prev_next_save button section
  .switch_btns_container {
    // border: 1px solid red;
    width: 75%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255, 255, 255);
    flex-wrap: wrap;
    gap: 4px;

    button {
      border: 1px solid #5e16da;
      background: white;
      color: #5e16da;

      &:hover {
        background: #5e16da;
        color: #fff;
      }
    }
  }

  @media (max-width: 768px) and (min-width: 351px) {
    .switch_btns_container {
      width: 100%;

      button {
        font-size: 14px;
        padding: 8px 16px;
      }
    }
  }

  @media (max-width: 350px) {
    .switch_btns_container {
      width: 100%;

      button {
        font-size: 12px;
        padding: 6px 12px;
      }
    }
  }

  //left section

  //left_section_question_container
  .left_section_question_container {
    padding: 10px 20px 20px 20px;
    padding-bottom: 0;
    position: "relative";

    #question-container-scroll {
      height: calc(80vh - 30px); // Adjust height to account for padding (10px top + 20px bottom)
      max-height: calc(80vh - 30px); // Ensure it doesn't exceed the viewport height
      overflow-x: hidden;
      overflow-y: auto; // Show scroll only when needed
      padding-bottom: 40px; // Ensure this padding is accounted for in the height calculation

      #questionContainer {
        .ques_title {
          font-weight: bold;
          font-size: 20px;
        }
        @media (max-width: 768px) and (min-width: 501px) {
          .ques_title {
            font-size: 18px;
          }
        }
        @media (max-width: 500px) {
          .ques_title {
            font-size: 16px;
          }
        }

        .ques_text {
          font-size: 18px;
          font-weight: 400;
        }
        @media (max-width: 768px) and (min-width: 501px) {
          .ques_text {
            font-size: 16px;
          }
        }
        @media (max-width: 500px) {
          .ques_text {
            font-size: 14px;
          }
        }
        .questions_item {
          .ques_ans_wrapper {
            display: flex;
            align-items: center;
            .idx_box {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 32px;
              width: 32px;
              border-radius: 4px;
              border-color: transparent;
              // margin-right: 10px;
            }
            .idx_answer {
              margin: 0;
              margin-left: 10px;
            }
          }
        }
        @media (max-width: 650px) {
          .questions_item {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;

            .option-card {
              font-size: 16px;
            }
            @media (max-width: 768px) and (min-width: 501px) {
              .option-card {
                font-size: 14px;
              }
            }
            @media (max-width: 500px) {
              .option-card {
                font-size: 12px;
              }
            }
          }
        }

        h5 {
          font-size: 18px;
        }
        @media (max-width: 768px) and (min-width: 501px) {
          h5 {
            font-size: 16px;
          }
        }
        @media (max-width: 500px) and (min-width: 401px) {
          h5 {
            font-size: 14px;
          }
        }
        @media (max-width: 400px) {
          h5 {
            font-size: 12px;
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(136, 136, 136, 0.5);
      border-radius: 10px;
      border: 2px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #888;
    }
  }

  @media (max-width: 768px) and (min-width: 501px) {
    .left_section_question_container {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    .left_section_question_container {
      width: 100%;
      padding: 10px;
    }
  }

  //right section

  //assesment_mcqs_button_container
  .assesment_mcqs_button_container {
    position: fixed;
    right: 0;
    height: 100vh;
    z-index: 1;
    border-left: 0.5px solid lightgray;

    .question_type_section_wrapper {
      //c2  add scrollbar
      overflow-x: hidden;
      overflow-y: scroll;
      .filter_red {
        background-color: #c8381e;
      }
      .filter_green {
        background-color: #78db21;
      }
      .filter_yellow {
        background-color: #f0a930;
      }
      .filter_black {
        background-color: #000000;
      }
      .filter_pink {
        background-color: #de16b9;
      }
      .filter_gray {
        background-color: gray;
      }
      .filter_blue {
        background-color: #1e25c8;
      }
      .color_span {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
      // c3 change ui of counter
      .filter_count {
        display: inline-block;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        color: #fff;
        background-color: #5e16da;

        text-align: center;
        font-size: 12px;
      }

      .info_section {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        padding: 16px 20px;

        .inline_filter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 8px;

          .left_side {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }
          p {
            margin: 0;
          }
        }

        .inline_info {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          padding: 8px;

          p {
            margin: 0;
          }
        }
      }

      .filter_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        .filters {
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          padding: 8px;
          border-bottom: 1px solid lightgray;

          .inline_filter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 8px;

            .left_side {
              display: flex;
              align-items: center;
              gap: 8px;

              label {
                display: flex;
                align-items: center;
                gap: 8px;
              }

              p {
                margin: 0;
              }
            }

            .right_side {
              .filter_count {
                display: inline-block;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                color: #fff;
                background-color: #5e16da;
                margin-left: 10px;
                text-align: center;
                font-size: 11px;
              }
            }
          }
        }
        .apply_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid lightgray;
          padding: 4px 8px;

          button {
            border: none;
            width: 100%;
            background-color: white;
            padding: 8px 16px;
            color: inherit;
            font-size: 16px;
          }
        }
      }

      .filter_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;

        .left_section {
          display: flex;
          align-items: center;
          margin-left: 16px;
          //c3 change cursor to pointer
          cursor: pointer;
          .type {
            display: inline-block;
            margin-left: 10px;
          }

          .filter_count {
            display: inline-block;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            color: #fff;
            background-color: #5e16da;
            margin-left: 10px;
            text-align: center;
            font-size: 12px;
          }
        }

        .right_section {
          margin-right: 16px;
          //c4 change cursor to pointer
          cursor: pointer;
          .type {
            display: inline-block;
            margin-left: 10px;
          }
        }
      }

      .question_type_section_item_wrapper {
        // border: 1px solid red;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 70vh;
        padding-bottom: 50px;

        // ::-webkit-scrollbar {
        //     width: 8px;
        // }

        // ::-webkit-scrollbar-track {
        //     background: transparent;
        // }

        // ::-webkit-scrollbar-thumb {
        //     background-color: rgba(136, 136, 136, 0.5);
        //     border-radius: 10px;
        //     border: 2px solid transparent;
        // }

        // ::-webkit-scrollbar-thumb:hover {
        //     background-color: #888;
        // }

        .question_type_section_item {
          // border: 1px solid red;
          margin-top: 10px;

          .title_container {
            // border: 1px solid green;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            // c4 cursor change to pointer
            cursor: pointer;

            .title {
              font-size: 18px;
            }
          }

          .pagination {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            .page-item {
              flex: 0 1 auto;
            }
            .ques_btn {
              // c4 pointer change on hover
              cursor: pointer;
            }
          }
        }
      }

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: rgba(136, 136, 136, 0.5);
        border-radius: 10px;
        border: 2px solid transparent;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #888;
      }
    }

    .submit_btn_container {
      // min-width: 250px;
      position: fixed;
      bottom: 0px;
      background: #fff;
      // border: 1px solid red;
      padding: 16px 0;
      width: 24%;
      //
      display: flex;
      align-items: center;
      justify-content: center;
      // width: auto;
      // position: relative;

      .submit_btn {
        width: 96%;
      }
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(136, 136, 136, 0.5);
      border-radius: 10px;
      border: 2px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #888;
    }
  }

  @media (max-width: 768px) {
    .assesment_mcqs_button_container {
      display: none;
    }
  }
  .slider_open_btn {
    position: fixed;
    bottom: 30vh;
    right: 0;
    background: #5e16da;
    color: white;
    padding: 10px;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
    z-index: 1000;
    width: auto;
    max-width: 40px;
  }
  @media (min-width: 769px) {
    .slider_open_btn {
      display: none;
    }
  }
  .mobile_assesment_mcqs_button_container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background: white;
    z-index: 1001;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform 1s ease-in-out;
    .question_type_section_wrapper {
      //c2  add scrollbar
      overflow-x: hidden;
      overflow-y: scroll;
      .filter_red {
        background-color: #c8381e;
      }
      .filter_green {
        background-color: #78db21;
      }
      .filter_yellow {
        background-color: #f0a930;
      }
      .filter_black {
        background-color: #000000;
      }
      .filter_pink {
        background-color: #de16b9;
      }
      .filter_gray {
        background-color: gray;
      }
      .filter_blue {
        background-color: #1e25c8;
      }
      .color_span {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
      // c3 change ui of counter
      .filter_count {
        display: inline-block;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        color: #fff;
        background-color: #5e16da;

        text-align: center;
        font-size: 12px;
      }

      .info_section {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        padding: 16px 20px;

        .inline_filter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 8px;

          .left_side {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }
          p {
            margin: 0;
          }
        }

        .inline_info {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          padding: 8px;

          p {
            margin: 0;
          }
        }
      }

      .filter_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        .filters {
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          padding: 8px;
          border-bottom: 1px solid lightgray;

          .inline_filter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 8px;

            .left_side {
              display: flex;
              align-items: center;
              gap: 8px;

              label {
                display: flex;
                align-items: center;
                gap: 8px;
              }

              p {
                margin: 0;
              }
            }

            .right_side {
              .filter_count {
                display: inline-block;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                color: #fff;
                background-color: #5e16da;
                margin-left: 10px;
                text-align: center;
                font-size: 11px;
              }
            }
          }
        }
        .apply_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid lightgray;
          padding: 4px 8px;

          button {
            border: none;
            width: 100%;
            background-color: white;
            padding: 8px 16px;
            color: inherit;
            font-size: 16px;
          }
        }
      }

      .filter_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;

        .left_section {
          display: flex;
          align-items: center;
          margin-left: 16px;
          //c3 change cursor to pointer
          cursor: pointer;
          .type {
            display: inline-block;
            margin-left: 10px;
          }

          .filter_count {
            display: inline-block;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            color: #fff;
            background-color: #5e16da;
            margin-left: 10px;
            text-align: center;
            font-size: 12px;
          }
        }

        .right_section {
          margin-right: 16px;
          //c4 change cursor to pointer
          cursor: pointer;
          .type {
            display: inline-block;
            margin-left: 10px;
          }
        }
      }

      .question_type_section_item_wrapper {
        // border: 1px solid red;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 70vh;
        padding-bottom: 50px;

        // ::-webkit-scrollbar {
        //     width: 8px;
        // }

        // ::-webkit-scrollbar-track {
        //     background: transparent;
        // }

        // ::-webkit-scrollbar-thumb {
        //     background-color: rgba(136, 136, 136, 0.5);
        //     border-radius: 10px;
        //     border: 2px solid transparent;
        // }

        // ::-webkit-scrollbar-thumb:hover {
        //     background-color: #888;
        // }

        .question_type_section_item {
          // border: 1px solid red;
          margin-top: 10px;

          .title_container {
            // border: 1px solid green;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            // c4 cursor change to pointer
            cursor: pointer;

            .title {
              font-size: 18px;
            }
          }

          .pagination {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            .page-item {
              flex: 0 1 auto;
            }
            .ques_btn {
              // c4 pointer change on hover
              cursor: pointer;
            }
          }
        }
      }

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: rgba(136, 136, 136, 0.5);
        border-radius: 10px;
        border: 2px solid transparent;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #888;
      }
    }

    .submit_btn_container {
      // min-width: 250px;
      position: fixed;
      bottom: 0px;
      background: #fff;
      // border: 1px solid red;
      padding: 10px;
      width: 100%;
      //
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: auto;
      // position: relative;

      .submit_btn {
        width: 96%;
      }
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(136, 136, 136, 0.5);
      border-radius: 10px;
      border: 2px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #888;
    }
  }

  .mobile_assesment_mcqs_button_container.open {
    transform: translateX(0);
  }

  @media (min-width: 769px) {
    .mobile_assesment_mcqs_button_container {
      display: none;
      transform: none;

    }
  }
  @media (max-width: 768px) and (min-width: 501px) {
    .mobile_assesment_mcqs_button_container {
      width: 40%;
    }
  }
  
  @media (max-width: 500px) {
    .mobile_assesment_mcqs_button_container {
      width: 75%;
    }
  }
  // modals
  .popup_modal {
    font-size: 30px;
    position: fixed;
    inset: 0;
    z-index: 1002;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      padding: 14px 22px;
      border: none;
      background: #5e16da;
      border-radius: 6px;
      cursor: pointer;
    }
    .modal-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 380px;
      width: 100%;
      padding: 30px 20px;
      border-radius: 24px;
      background-color: #fff;
      opacity: 1;
      transition: all 0.3s ease;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }

    .modal-box h2 {
      margin: 0;
      margin-top: 20px;
      font-size: 25px;
      font-weight: 500;
      color: #333;
    }
    .modal-box h3 {
      font-size: 18px;
      font-weight: 400;
      color: #333;
      text-align: center;
    }
    .modal-box .buttons {
      margin-top: 10px;
    }
    .modal-box button {
      padding: 6px 12px;
      margin: 0 10px;
    }
    .icon-success {
      color: #5e16da;
    }
    .icon-error {
      color: red;
    }
  }
}

// review page
.review_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  gap: 30px;
  width: 100vw;
  height: auto;
  margin: 0;

  .review_heading {
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      color: #0d0c22;
    }
    h2 {
      font-size: 30px;
    }
  }

  @media (max-width: 768px) and (min-width: 501px) {
    .review_heading {
      h1 {
        font-size: 22px;
      }
      h2 {
        font-size: 26px;
      }
    }
  }

  @media (max-width: 500px) {
    .review_heading {
      h1 {
        font-size: 18px;
      }
      h2 {
        font-size: 22px;
      }
    }
  }

  .review_rating {
    p {
      font-size: 18px;
      color: #0d0c22;
      text-align: center;
      width: 100%;
    }

    input[type="radio"] {
      display: none;
    }
    .star {
      cursor: pointer;
      font-size: 2rem;
      margin: 10px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 532px;

    textarea {
      width: 100%;
      height: 150px;
      padding: 10px;
      margin: 10px 0;
      border: 1px solid #e4e5e9;
      border-radius: 8px;
      background-color: #e4e5e9;
      outline: none;
      font-size: 18px;
    }

    button {
      margin-top: 30px;
      background-color: #5e16da;
      color: white;
      border-radius: 8px;
      padding: 10px 20px;
      width: 100%;
      font-weight: 500;
      border: none;
      font-size: 16;
    }
    p {
      margin-top: 8px;
      text-align: start;
      width: 100%;
    }
  }

  @media (max-width: 768px) and (min-width: 501px){
    form {
      width: 100%;

      textarea {
        width: 60%;
      }

      button {
        width: 60%;
      }

      p {
        width: 60%;
      }
    }
  }

  @media (max-width: 500px) {
    form {
      width: 100%;

      textarea {
        width: 85%;
      }

      button {
        width: 85%;
      }

      p {
        width: 85%;
      }
    }
  }

  .popup_modal {
    font-size: 30px;
    position: fixed;
    inset: 0;
    z-index: 1002;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      padding: 14px 22px;
      border: none;
      background: #5e16da;
      border-radius: 6px;
      cursor: pointer;
    }
    .modal-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 600px;
      width: 100%;
      padding: 30px;
      border-radius: 24px;
      background-color: #fff;
      opacity: 1;
      transition: all 0.3s ease;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      gap: 10px;

      .icon_review {
        color: #e7b66b;
      }

      h2 {
        margin: 0;
        margin-top: 20px;
        font-size: 25px;
        font-weight: 500;
        color: #333;
        text-align: center;
      }

      h3 {
        font-size: 18px;
        font-weight: 400;
        color: #333;
        text-align: center;
      }

      .buttons {
        margin-top: 10px;
        width: 100%;
        .close-btn {
          width: 100%;
        }
      }
    }
  }
}

// attempted q
.attempted_q_modal {
  font-size: 30px;
  position: fixed;
  inset: 0;
  z-index: 1002;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    width: 100%;
    padding: 32px 25px;
    border-radius: 16px;
    background-color: #fff;
    opacity: 1;
    transition: all 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    gap: 35px;

    .upper_section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 16px;

      .left_section {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .right_section {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .right_side {
        display: flex;
        flex-direction: column;
      }
    }

    .lower_section {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;

      .buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;

        button {
          font-size: 18px;
          font-weight: 500;
          color: #fff;
          padding: 12px 40px;
          border: none;
          background: #5e16da;
          border-radius: 6px;
          cursor: pointer;
        }

        .go-back {
          background-color: #efe8fb;
          color: #5e16da;
          border: 1px solid var(--400, #9b8afb);
        }
      }
    }
  }
}


// timer modal
.timer_modal {
  font-size: 30px;
  position: fixed;
  inset: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 380px;
    width: 100%;
    padding: 16px;
    border-radius: 10px;
    background-color: #fff;
    opacity: 1;
    transition: all 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    .timer {
      width: 100%;
      display: flex;
      justify-content: end;
      .time {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: white;
        font-weight: 600;
        width: 28px;
        height: 28px;
        background-color: #8247ff;
        box-shadow: 5px 5px 10px 0px #8974b8;
        border: 3px solid #8d65e1;
        border-radius: 30px;
      }
    }

    .timer_text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        color: #121212;
        font-size: 22px;
        font-weight: 600;
      }

      .timer_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #fba63c;
      }
    }
  }
}