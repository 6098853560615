.loader_container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    .loader {
      width: 80px;
      padding: 8px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: #5e16da;
      --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--_m);
      mask: var(--_m);
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
      animation: l3 0.7s infinite linear;
    }
    @keyframes l3 {
      to {
        transform: rotate(1turn);
      }
    }
  }
  
  .popup_modal {
    font-size: 30px;
    position: fixed;
    inset: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }