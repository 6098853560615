.chat_wrapper {
  width: 100%;
  // border: 1px solid red;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  .left_section {
    width: 20%;
    // border: 2px solid green;
    transition: width 0.3s ease;
    position: relative!important;

    .chat_history_container {
      width: 100%;
      height: 100vh;
      background-color: #f4f4f4;
      transition: transform 0.3s ease;
      overflow-x: hidden;
      overflow-y: scroll;
      //
      padding: 0 10px 20px;
    
      .new_chat_container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        width: 90%;
        // border: 1px solid red;
        background: #F4F4F4;
        padding: 5px 0;
        .title {}
        .icon {
          cursor: pointer;
        }
      }

      .chat_history_list {
        margin-top: 40px;
        padding: 0;
        border: 1px solid transparent;
        li {
          padding: 5px;
          color: black;
          cursor: pointer;
          margin-bottom: 5px;
          &:hover {
            border: 1px solid #BEBEBE;
            border-radius: 8px;
            background: #BEBEBE;

          }
        }

      }
    }
    ::-webkit-scrollbar {
      width: 8px;
    }
  
    ::-webkit-scrollbar-track {
      background: transparent;
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: rgba(136, 136, 136, 0.5);
      border-radius: 10px;  
      border: 2px solid transparent;  
    }
  
    ::-webkit-scrollbar-thumb:hover {
      background-color: #888;  
    }
    
    
    .chat_history_container.closed {
      transform: translateX(-90%);
    }
    
    .toggle_button {
      width: 120px;
      height: 35px;
      border-radius: 4px;
      //
      position: absolute;
      right: -43px;
      background-color: #5e16da;
      color: white;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      // border-radius: 0 5px 5px 0;
      top: 50%;
      transform: rotate(90deg);

      svg {
        transform: rotate(270deg);
      }
    }
    
  }
  
  .right_section {
    // border: 2px solid yellow;
    width: 80%;
    position: relative;
    .title_container {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .input_wrapper {
      position: absolute;
      bottom: 0;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      //
      width: 80%;
      margin: 70px auto;
      outline: 2px solid transparent;
      outline-offset: 2px;
      border-radius: 0.375rem;
      transition: outline 0.3s ease;
      // border: 1px solid green;
      &:focus {
        outline: 2px solid grey;
      }
  
      textarea {
        width: 100%;
        height: 70px;
        padding: 10px;
        border: none !important;
        resize: none;
      }
  
      input[type="text"],
      textarea {
        caret-color: #20B8CC;
        color: black;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        outline: none;
      }
  
      input[type="text"]:focus,
      textarea:focus {
        border: none;
      }
  
  
      input[type="text"]:focus,
      textarea:focus {
        border: 2px solid #eee;
      }
  
      .option_wrapper {
        padding: 10px;
        position: relative;
  
        .focus_button {
          border-radius: 16px;
          padding: 5px 8px;
          background: #fff;
          border: 1px solid transparent;
          transition: background 0.3s, border 0.3s;
  
          &:hover {
            background: #ccc;
            border: 1px solid black;
          }
  
          svg {
            font-size: 12px;
            margin-right: 10px;
          }
        }
  
        .attach_button {
          border-radius: 16px;
          padding: 5px 8px;
          background: #fff;
          border: 1px solid transparent;
          transition: background 0.3s, border 0.3s;
          margin-left: 15px;
  
          &:hover {
            background: #ccc;
            border: 1px solid black;
          }
  
          svg {
            font-size: 12px;
            margin-right: 10px;
          }
        }
  
        // dropdpwn focus
        .dropdown_focus_container {
          // border: 1px solid red;
          width: 50%;
          display: flex;
          align-items: center;
          background: grey;
          border-radius: 4px;
          padding: 5px;
          position: absolute;
          top: 70px;
          left: 0;
          .item {
            width: 16%;
            // border: 1px solid green;
            border-radius: 6px;
            margin: 5px;
            text-align: center;
            color: black;
            background: white;
  
            &.active-pill {
              background-color: black;
              color: white;
            }
            
            span {}
  
            p {}
          }
        }
      }
  
    }

  }

  .open_slider_button {
    width: 120px;
    height: 35px;
    border-radius: 4px;
    // 
    position: absolute;
    background-color: #5e16da;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    // border-radius: 0 5px 5px 0;
    top: 50%;
    left: -45px;
    transform: rotate(90deg);

    svg {
      transform: rotate(270deg);
    }
  }

}