//register
.user_details_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .item {
        width: 48%;
    }
}