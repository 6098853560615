body {
  overflow: auto;
}

.registration_container {
  display: flex;
  justify-content: start;
  width: 100%;

  .left_container {
    position: relative;
    height: 100vh;
    background: "#B6A3D7";
    padding: 0px 30px;
    padding-bottom: 50px;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding-top: 30px;
    position: sticky;
    width: 45vw;

    .illustrations {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      justify-content: space-between;

      .registration-illustration {
        width: 95%;
        margin: 0px, 15px;
      }
      .registration-illustration-clouds {
        width: 70%;
        margin: 0px, 15px;
      }
    }
    .logo {
      width: 160px;
    }
  }

  .right_container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 20px;
    padding: 100px 110px;
    background-color: white;
    width: 100%;
    position: relative; /* Ensure the footer is positioned relative to this container */

    h1 {
      font-size: 40px;
      font-weight: 700;
      color: #100f14;
    }

    h6 {
      margin-top: 4px;
      font-size: 18px;
      font-weight: 300;
    }

    form {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 20px;

      .same-line-input-email {
        display: flex;
        flex-direction: row;
        gap: 30px;
        width: 100%;

        .input_box {
          display: flex;
          flex-direction: column;
          justify-content: start;
          gap: 10px;
          width: 80%;
        }
      }

      .same-line-input {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 30px;
      }

      .input_box {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 10px;
        width: 50%;

        .input_label {
          color: #9794aa;
          font-weight: 500;
        }

        input {
          border-radius: 6px;
          padding: 12px 20px;
          border: 2px solid #cbcad7;
          box-shadow: 0px 2px 2px 0px #0000001a;
          outline: none;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          font-weight: 600;
          color: #49475a;
        }

        ::placeholder {
          font-weight: 500;
          color: #686677;
        }

        input:focus {
          border: 2px solid #5925dc;
        }

        select {
          border-radius: 6px;
          padding: 12px 20px;
          border: 2px solid #cbcad7;
          box-shadow: 0px 2px 2px 0px #0000001a;
          outline: none;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          font-weight: 600;
          color: #49475a;
          cursor: pointer;
        }
      }

      .phone_input_label {
        color: #9794aa;
        font-weight: 500;
        width: 50%;
        gap: 10px;
      }

      .phone_input {
        display: flex;
        justify-content: start;
        padding: 10px 0px;
        width: 100%;
        gap: 10px;

        .country_code {
          border-radius: 6px;
          padding: 0px 12px;
          border: 2px solid #cbcad7;
          box-shadow: 0px 2px 2px 0px #0000001a;
          outline: none;
          color: #49475a;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          img {
            width: 40px;
            height: 40px;
          }
        }

        input {
          border-radius: 6px;
          padding: 12px 20px;
          border: 2px solid #cbcad7;
          box-shadow: 0px 2px 2px 0px #0000001a;
          outline: none;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          font-weight: 600;
          color: #49475a;
        }

        ::placeholder {
          font-weight: 500;
          color: #686677;
        }

        input:focus {
          border: 2px solid #5925dc;
        }
      }

      .btn {
        height: 50px;
        width: 20%;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        padding: 14px 22px;
        border: none;
        background: #5e16da;
        border-radius: 6px;
        cursor: pointer;
      }
    }

    .footer_container {
      position: absolute;
      bottom: 10px;
      right: 20px;
      color: #888;
    }
  }

  .main_container_mobile {
    padding: 40px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    .logo {
      width: 140px;
      margin-bottom: 20px;
    }
  
    h1 {
      font-size: 32px;
      font-weight: 700;
      color: #100f14;
    }
  
    h6 {
      margin-top: 1px;
      font-size: 12px;
      font-weight: 300;
    }

    form {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      gap: 12px;

      .same-line-input-email {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        .input_box {
          display: flex;
          flex-direction: column;
          justify-content: start;
          gap: 10px;
          width: 100%;
        }
      }

      .same-line-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .input_box {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 10px;
        width: 100%;

        .input_label {
          color: #9794aa;
          font-weight: 500;
          font-size: 14px;
        }

        input {
          border-radius: 6px;
          padding: 12px 20px;
          border: 2px solid #cbcad7;
          box-shadow: 0px 2px 2px 0px #0000001a;
          outline: none;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          font-weight: 600;
          color: #49475a;
        }

        ::placeholder {
          font-weight: 500;
          color: #686677;
        }

        input:focus {
          border: 2px solid #5925dc;
        }

        select {
          border-radius: 6px;
          padding: 12px 20px;
          border: 2px solid #cbcad7;
          box-shadow: 0px 2px 2px 0px #0000001a;
          outline: none;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          font-weight: 600;
          color: #49475a;
          cursor: pointer;
        }
      }

      .phone_input_label {
        color: #9794aa;
        font-weight: 500;
        width: 100%;
        gap: 10px;
        font-size: 14px;
      }

      .phone_input {
        display: flex;
        justify-content: start;
        padding: 10px 0px;
        width: 100%;
        gap: 10px;

        .country_code {
          border-radius: 6px;
          padding: 0px 12px;
          border: 2px solid #cbcad7;
          box-shadow: 0px 2px 2px 0px #0000001a;
          outline: none;
          color: #49475a;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          img {
            width: 40px;
            height: 40px;
          }
        }

        input {
          border-radius: 6px;
          padding: 12px 20px;
          border: 2px solid #cbcad7;
          box-shadow: 0px 2px 2px 0px #0000001a;
          outline: none;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          font-weight: 600;
          color: #49475a;
        }

        ::placeholder {
          font-weight: 500;
          color: #686677;
        }

        input:focus {
          border: 2px solid #5925dc;
        }
      }

      .btn {
        height: 50px;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        padding: 14px 22px;
        border: none;
        background: #5e16da;
        border-radius: 6px;
        cursor: pointer;
      }
    }

    .registration-illustration {
      width: 100%;
      margin: 10px, 15px;
    }

    .footer_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Media query for tablet mode
@media (max-width: 768px) {
  .registration_container {
    .left_container {
      width: 40vw;
      .logo {
        width: 140px;
      }
    }

    .right_container {
      width: 60vw;
      padding: 50px 50px;

      h1 {
        font-size: 32px;
      }

      h6 {
        margin-top: 2px;
        font-size: 16px;
      }

      form {
        margin-top: 12px;
        width: 100%;
        gap: 12px;

        .same-line-input-email {
          flex-direction: column;
          gap: 12px;

          .input_box {
            width: 100%;

            .input_label {
              font-size: 14px;
            }
          }
        }

        .same-line-input {
          flex-direction: column;
          gap: 12px;

          .input_box {
            width: 100%;

            .input_label {
              font-size: 14px;
            }
          }

          .phone_input_label {
            font-size: 14px;
            width: 100%;
          }
        }

        .btn {
          width: 100%;
          margin-top: 4px;
          margin-bottom: 20px;
        }
      }
    }
  }
}